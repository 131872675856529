const commonSettings = {
  centeredSlides: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
};

var swiper = new Swiper('.js_swiperMain', {
  loop: true,
  autoplay: {
    delay: 5000,
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      ...commonSettings,
    },
    503: {
      slidesPerView: 1.1,
      ...commonSettings,
    },
    552: {
      slidesPerView: 1.2,
      ...commonSettings,
    },
    600: {
      slidesPerView: 1.4,
      spaceBetween: 20,
      ...commonSettings,
    },
    705: {
      slidesPerView: 1.6,
      spaceBetween: 20,
      ...commonSettings,
    },
    807: {
      slidesPerView: 1.8,
      spaceBetween: 25,
      ...commonSettings,
    },
    912: {
      slidesPerView: 2,
      spaceBetween: 25,
      ...commonSettings,
    },
    1014: {
      slidesPerView: 2.2,
      spaceBetween: 30,
      ...commonSettings,
    },
    1124: {
      slidesPerView: 2.5,
      spaceBetween: 30,
      ...commonSettings,
    },
    1280: {
      slidesPerView: 2.8,
      spaceBetween: 30,
      ...commonSettings,
    },
    1439: {
      slidesPerView: 3.2,
      spaceBetween: 30,
      ...commonSettings,
    },
    1640: {
      slidesPerView: 3.6,
      spaceBetween: 30,
      ...commonSettings,
    },
    1847: {
      slidesPerView: 4,
      spaceBetween: 30,
      ...commonSettings,
    },
    2056: {
      slidesPerView: 4.5,
      spaceBetween: 30,
      ...commonSettings,
    },
    2200: {
      slidesPerView: 4.5,
      spaceBetween: 50,
      ...commonSettings,
    },
  },
});



var swiper = new Swiper('.js_swiperGallery', {
  loop: true,
  speed: 4000,
  allowTouchMove: false,
  autoplay: {
    delay: 0,
  },
  spaceBetween: 20,
  breakpoints: {
    0: {
      slidesPerView: 2
    },
    500: {
      slidesPerView: 5.5
    },
  }
});